import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const chunk0 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faRectangleWide"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faTreasureChest"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faTruckPickup"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faArchway"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faHandPointRight"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faExclamationSquare"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faGopuram"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faBatteryBolt"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faPhoneOffice"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faRedo"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faHospitalUser"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faHandReceiving"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faHandPointer"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faArrowRight"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faVihara"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faLongArrowUp"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faCarCrash"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faCarBus"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faRadiationAlt"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faCameraMovie"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faWarehouse"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faArrowAltSquareRight"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faClock"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faFileInvoice"
    ),
    import(
      /* webpackChunkName: "faChunk0" */ "@fortawesome/pro-light-svg-icons/faArrowSquareLeft"
    ),
  ]);
const chunk1 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faGasPump"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faMicrochip"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faAngleDoubleRight"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faCar"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faCommentsAltDollar"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faPrintSlash"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faStethoscope"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faSortAlphaDown"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faGarageOpen"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faPhoneSquareAlt"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faArrowFromBottom"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faAngleDoubleLeft"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faPowerOff"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faCarBuilding"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faTvRetro"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faLongArrowAltDown"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faTachometerFastest"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faVhs"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faBolt"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faAnalytics"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faCamcorder"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faArrowAltUp"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faCloudUploadAlt"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faArrowAltSquareDown"
    ),
    import(
      /* webpackChunkName: "faChunk1" */ "@fortawesome/pro-light-svg-icons/faVideoPlus"
    ),
  ]);
const chunk2 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faStream"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faFrown"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faHelicopter"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faSortSizeDownAlt"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faDesktopAlt"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faAbacus"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faCommentAltPlus"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faAirFreshener"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faFolderUpload"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faConveyorBeltAlt"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faArrowUp"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faBagsShopping"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faChevronDoubleLeft"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faCommentsAlt"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faPoop"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faAmpGuitar"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faStreetView"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faChartArea"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faSitemap"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faTireFlat"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faProjectDiagram"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faAlarmClock"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faHandshake"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faFolders"
    ),
    import(
      /* webpackChunkName: "faChunk2" */ "@fortawesome/pro-light-svg-icons/faComputerSpeaker"
    ),
  ]);
const chunk3 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faWebcamSlash"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faClosedCaptioning"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faChevronSquareRight"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faIgloo"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faThunderstorm"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faCaretLeft"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faThumbsDown"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faCaravanAlt"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faOutlet"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faExpandArrows"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faStarExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faCaretSquareDown"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faDroneAlt"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faExternalLinkSquareAlt"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faLocationSlash"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faSortShapesDown"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faCommentTimes"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faUserHardHat"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faPiggyBank"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faCamera"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faRectangleLandscape"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faCompressArrowsAlt"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faCommentDollar"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faSimCard"
    ),
    import(
      /* webpackChunkName: "faChunk3" */ "@fortawesome/pro-light-svg-icons/faDollyEmpty"
    ),
  ]);
const chunk4 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faIdCard"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faIdCardAlt"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faFilmAlt"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faBell"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faHomeLg"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faSortAmountDownAlt"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faDrone"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faSortAmountUpAlt"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faVolumeMute"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faMoneyBillAlt"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faHospitals"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faAlbumCollection"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faMapMarkedAlt"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faBrush"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faTaxi"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faThumbsUp"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faToggleOff"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faAtomAlt"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faJug"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faTable"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faCompress"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faPaintRoller"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faPoll"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faArrowAltRight"
    ),
    import(
      /* webpackChunkName: "faChunk4" */ "@fortawesome/pro-light-svg-icons/faChartLine"
    ),
  ]);
const chunk5 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faHandPointLeft"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faRadiation"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faArrows"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faVideoSlash"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faCarWash"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faMoneyCheckEdit"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faNewspaper"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faConstruction"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faLayerPlus"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faPlaceOfWorship"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faArrowToLeft"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faVideo"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faHouseFlood"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faTabletRugged"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faDesktop"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faUserTag"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faSynagogue"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faReceipt"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faCopyright"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faArrowAltCircleDown"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faArrowAltCircleRight"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faTruckContainer"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faSortShapesDownAlt"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faBroadcastTower"
    ),
    import(
      /* webpackChunkName: "faChunk5" */ "@fortawesome/pro-light-svg-icons/faHeat"
    ),
  ]);
const chunk6 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faRulerTriangle"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faUmbrella"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faPause"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faUserTie"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faGasPumpSlash"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faBatteryQuarter"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faFunnelDollar"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faAngleDown"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faRepeat1"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faMousePointer"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faBadgeDollar"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faFarm"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faCheckSquare"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faAngleDoubleUp"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faSms"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faForklift"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faCaretRight"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faHouseDamage"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faSortSizeDown"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faCommentMedical"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faMoneyCheckAlt"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faFolderTimes"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faUserSecret"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faScannerTouchscreen"
    ),
    import(
      /* webpackChunkName: "faChunk6" */ "@fortawesome/pro-light-svg-icons/faCompressAlt"
    ),
  ]);
const chunk7 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faCompass"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faStarHalf"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faCheck"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faRetweet"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faLongArrowDown"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faSortAlt"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faArrowAltToLeft"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faSmilePlus"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faWatchCalculator"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faBrain"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faDollyFlatbedEmpty"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faCode"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faBarcodeScan"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faShippingTimed"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faCarBump"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faCoins"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faCameraRetro"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faCaretCircleRight"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faBriefcase"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faFire"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faSatellite"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faChevronSquareUp"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faArrowCircleDown"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faUndoAlt"
    ),
    import(
      /* webpackChunkName: "faChunk7" */ "@fortawesome/pro-light-svg-icons/faArrowAltToBottom"
    ),
  ]);
const chunk8 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faArrowFromLeft"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faWindowRestore"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faAlbum"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faChurch"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faWindowAlt"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faArrowAltFromTop"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faWarehouseAlt"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faAngleLeft"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faCloudUpload"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faSortUp"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faBalanceScale"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faTools"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faAngleDoubleDown"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faMicrophone"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faPollH"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faShovel"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faThumbtack"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faTrophyAlt"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faUndo"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faChargingStation"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faSocks"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faTachometerAltFast"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faCommentMusic"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faCommentSmile"
    ),
    import(
      /* webpackChunkName: "faChunk8" */ "@fortawesome/pro-light-svg-icons/faSortDown"
    ),
  ]);
const chunk9 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faMosque"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faVolumeUp"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faLightbulbExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faColumns"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faChevronSquareLeft"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faStar"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faFileChartPie"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faTachometerAltAverage"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faLink"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faInfoCircle"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faTachometer"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faCalendar"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faArrowSquareRight"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faBurn"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faChartBar"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faBadgeCheck"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faRepeatAlt"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faChevronCircleUp"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faCommentAltDollar"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faPenNib"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faSparkles"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faCommentAltEdit"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faBookmark"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faTruck"
    ),
    import(
      /* webpackChunkName: "faChunk9" */ "@fortawesome/pro-light-svg-icons/faBan"
    ),
  ]);
const chunk10 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faCommentLines"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faSignOutAlt"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faSensorFire"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faSortAlphaDownAlt"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faDatabase"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faIndustry"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faShieldCheck"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faTags"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faSortShapesUpAlt"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faMagic"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faWallet"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faExclamationCircle"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faHospital"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faTachometerAltSlowest"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faVolume"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faMoneyCheck"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faSearchLocation"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faCameraPolaroid"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faEdit"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faBatterySlash"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faMonument"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faAngleRight"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faHouseNight"
    ),
    import(
      /* webpackChunkName: "faChunk10" */ "@fortawesome/pro-light-svg-icons/faUnlink"
    ),
  ]);
const chunk11 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faCoffee"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faCommentAltMedical"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faWater"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faPaste"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faLongArrowAltLeft"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faRouter"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faSolarPanel"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faHammer"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faShareSquare"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faShareAll"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faBackward"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faPrint"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faMicrophoneAlt"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faSortNumericDown"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faSensor"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faChevronCircleDown"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faArrowSquareUp"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faMapMarked"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faKaaba"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faTvMusic"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faCartArrowDown"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faEnvelopeOpenDollar"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faCarSide"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faFastBackward"
    ),
    import(
      /* webpackChunkName: "faChunk11" */ "@fortawesome/pro-light-svg-icons/faStop"
    ),
  ]);
const chunk12 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faDollyFlatbedAlt"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faMindShare"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faEnvelopeOpenText"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faCommentAltExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faMotorcycle"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faRepeat"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faTachometerAltFastest"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faDollyFlatbed"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faBrowser"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faTabletAndroid"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faCabinetFiling"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faTvAlt"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faClinicMedical"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faCommentMinus"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faHouseDay"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faPodium"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faSave"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faBarcodeAlt"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faShare"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faSchool"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faSackDollar"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faShoppingBag"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faArrowToRight"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faCommentAltMinus"
    ),
    import(
      /* webpackChunkName: "faChunk12" */ "@fortawesome/pro-light-svg-icons/faVolumeDown"
    ),
  ]);
const chunk13 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faAtom"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faSatelliteDish"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faHospitalAlt"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faChevronRight"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faSdCard"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faHandPointDown"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faStoreAlt"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faArrowAltToTop"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faCarMechanic"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faHardHat"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faLevelDownAlt"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faDumpster"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faGarage"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faPooStorm"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faFax"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faPencilAlt"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faCalendarPlus"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faTachometerAlt"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faPhoneVolume"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faUserCrown"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faStore"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faSensorSmoke"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faHandHoldingBox"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faBoxCheck"
    ),
    import(
      /* webpackChunkName: "faChunk13" */ "@fortawesome/pro-light-svg-icons/faMegaphone"
    ),
  ]);
const chunk14 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faCaretCircleDown"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faRecycle"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faTabletAndroidAlt"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faEject"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faCaretDown"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faShuttleVan"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faSun"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faTrademark"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faTasks"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faBellSlash"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faBells"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faSpeaker"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faBalanceScaleLeft"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faMobileAndroid"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faTrain"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faRuler"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faAddressBook"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faBirthdayCake"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faSync"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faKeynote"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faCarAlt"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faPhoneSlash"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faCommentExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faSack"
    ),
    import(
      /* webpackChunkName: "faChunk14" */ "@fortawesome/pro-light-svg-icons/faHandsUsd"
    ),
  ]);
const chunk15 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faCarBattery"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faPodcast"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faChartNetwork"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faLongArrowAltUp"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faCameraAlt"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faEnvelopeOpen"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faChevronLeft"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faToriiGate"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faTasksAlt"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faCaretCircleLeft"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faMicrophoneSlash"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faMailBulk"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faExternalLinkSquare"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faUsbDrive"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faChartPieAlt"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faShoppingCart"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faCommentAlt"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faAudioDescription"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faTruckMonster"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faBullseyeArrow"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faWebcam"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faPhotoVideo"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faIslandTropical"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faTachometerFast"
    ),
    import(
      /* webpackChunkName: "faChunk15" */ "@fortawesome/pro-light-svg-icons/faAddressCard"
    ),
  ]);
const chunk16 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faPeopleArrows"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faSensorAlert"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faArrowAltFromBottom"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faCalendarExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faChimney"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faLeaf"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faCommentAltDots"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faAngleUp"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faCommentAltCheck"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faCalendarAlt"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faHistory"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faAward"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faMicroscope"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faCaretSquareLeft"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faBox"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faAlarmExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faCreditCardBlank"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faSpeakers"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faRss"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faMoneyBillWave"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faExternalLinkAlt"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faInboxOut"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faCloudDownload"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faLevelDown"
    ),
    import(
      /* webpackChunkName: "faChunk16" */ "@fortawesome/pro-light-svg-icons/faExpand"
    ),
  ]);
const chunk17 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faCommentsDollar"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faLampDesk"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faUpload"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faRandom"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faUsers"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faEnvelopeSquare"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faShredder"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faLightbulbDollar"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faPhoneLaptop"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faHotel"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faFan"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faLandmarkAlt"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faDotCircle"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faMapMarkerExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faPercentage"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faTire"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faTrophy"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faHomeLgAlt"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faScannerImage"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faHomeAlt"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faSyncAlt"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faCameraHome"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faGraduationCap"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faMarker"
    ),
    import(
      /* webpackChunkName: "faChunk17" */ "@fortawesome/pro-light-svg-icons/faWaveformPath"
    ),
  ]);
const chunk18 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faShieldAlt"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faTachometerAltSlow"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faFolderDownload"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faWifiSlash"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faSortShapesUp"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faMobile"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faStamp"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faArrowAltFromLeft"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faPhonePlus"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faFileChartLine"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faUser"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faSkullCrossbones"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faBalanceScaleRight"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faMouseAlt"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faFolderMinus"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faWifi"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faComputerClassic"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faUserPlus"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faBook"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faPenFancy"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faPalletAlt"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faProjector"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faMoneyBill"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faComment"
    ),
    import(
      /* webpackChunkName: "faChunk18" */ "@fortawesome/pro-light-svg-icons/faArrowAltDown"
    ),
  ]);
const chunk19 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faFastForward"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faVolumeOff"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faCommentEdit"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faDiscDrive"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faChevronDoubleUp"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faRssSquare"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faTally"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faArrowFromRight"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faSeedling"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faChartLineDown"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faHeadphones"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faBullhorn"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faBetamax"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faCoin"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faForward"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faFilmCanister"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faArrowsAlt"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faLongArrowLeft"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faArrowAltCircleUp"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faServer"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faImagePolaroid"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faArchive"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faArrowAltSquareLeft"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faDonate"
    ),
    import(
      /* webpackChunkName: "faChunk19" */ "@fortawesome/pro-light-svg-icons/faCommentAltLines"
    ),
  ]);
const chunk20 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faRv"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faBusinessTime"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faClipboardList"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faSignInAlt"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faTag"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faCheckCircle"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faStopCircle"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faArrowCircleUp"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faBatteryThreeQuarters"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faUserShield"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faMp3Player"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faBriefcaseMedical"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faTriangle"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faTrailer"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faSortAlphaUp"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faChevronDoubleRight"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faBadge"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faTireRugged"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faContainerStorage"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faCommentAltTimes"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faSignOut"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faFluxCapacitor"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faBracketsCurly"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faFilm"
    ),
    import(
      /* webpackChunkName: "faChunk20" */ "@fortawesome/pro-light-svg-icons/faDownload"
    ),
  ]);
const chunk21 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faDigging"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faWind"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faUserLock"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faCloudDownloadAlt"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faBoombox"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faGiftCard"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faHeart"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faStickyNote"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faCity"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faTextHeight"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faArrowAltLeft"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faFolderOpen"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faSortNumericDownAlt"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faBellOn"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faExclamationTriangle"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faChevronCircleLeft"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faUserFriends"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faPlayCircle"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faQuoteLeft"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faSortAmountDown"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faGem"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faBatteryFull"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faPauseCircle"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faPaperclip"
    ),
    import(
      /* webpackChunkName: "faChunk21" */ "@fortawesome/pro-light-svg-icons/faCars"
    ),
  ]);
const chunk22 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faExpandWide"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faArrowsAltV"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faPenAlt"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faReplyAll"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faIndustryAlt"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faMusic"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faCartPlus"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faClipboardCheck"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faMicrophoneAltSlash"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faArrowAltSquareUp"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faCalculator"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faFile"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faAd"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faCompressWide"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faPercent"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faReply"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faTv"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faTrafficCone"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faSortAmountUp"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faQuoteRight"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faCommentDots"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faSensorOn"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faScanner"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faSmile"
    ),
    import(
      /* webpackChunkName: "faChunk22" */ "@fortawesome/pro-light-svg-icons/faGarageCar"
    ),
  ]);
const chunk23 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faKey"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faGift"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faDraftingCompass"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faArrowCircleRight"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faBullseye"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faLanguage"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faBullseyePointer"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faFileInvoiceDollar"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faTachometerAverage"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faMoneyCheckEditAlt"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faToolbox"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faSortNumericUp"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faRedoAlt"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faCut"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faInboxIn"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faTachometerSlow"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faCommentAltSmile"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faBatteryEmpty"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faCaretSquareUp"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faArrowAltFromRight"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faConveyorBelt"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faStepBackward"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faIconsAlt"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faEraser"
    ),
    import(
      /* webpackChunkName: "faChunk23" */ "@fortawesome/pro-light-svg-icons/faPlaneDeparture"
    ),
  ]);
const chunk24 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faCaretUp"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faRetweetAlt"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faChevronDoubleDown"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faBuilding"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faCommentPlus"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faMouse"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faCarTilt"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faRulerHorizontal"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faSignalStream"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faGlasses"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faCreditCard"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faSortNumericUpAlt"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faMicrophoneStand"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faMoneyBillWaveAlt"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faSearchDollar"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faHdd"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faFileVideo"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faInventory"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faWindWarning"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faEthernet"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faArrowsH"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faCaretSquareRight"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faPoo"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faPlay"
    ),
    import(
      /* webpackChunkName: "faChunk24" */ "@fortawesome/pro-light-svg-icons/faCommentCheck"
    ),
  ]);
const chunk25 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faMobileAlt"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faMapMarkerAlt"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faWindTurbine"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faSuitcaseRolling"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faPhoneSquare"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faRegistered"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faCarGarage"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faHighlighter"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faArrowSquareDown"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faStarHalfAlt"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faWrench"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faChartPie"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faSortAlphaUpAlt"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faLaptopHouse"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faLevelUpAlt"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faWagonCovered"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faLightbulbSlash"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faScannerKeyboard"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faFileExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faBadgePercent"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faHandHeart"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faRepeat1Alt"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faLightbulbOn"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faCloudMusic"
    ),
    import(
      /* webpackChunkName: "faChunk25" */ "@fortawesome/pro-light-svg-icons/faHandPointUp"
    ),
  ]);
const chunk26 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faSortSizeUpAlt"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faTabletAlt"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faClipboard"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faWaveform"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faLaptopCode"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faMeh"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faArrowDown"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faBatteryHalf"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faLocation"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faScrewdriver"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faCompactDisc"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faShippingFast"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faCaravan"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faCreditCardFront"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faGlobeAsia"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faArrowFromTop"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faHeadSideHeadphones"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faPhoneAlt"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faCoffeePot"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faBookOpen"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faArrowAltToRight"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faCircle"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faToggleOn"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faArrowAltCircleLeft"
    ),
    import(
      /* webpackChunkName: "faChunk26" */ "@fortawesome/pro-light-svg-icons/faDolly"
    ),
  ]);
const chunk27 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faFireAlt"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faAmbulance"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faBusAlt"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faBarcodeRead"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faCaretCircleUp"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faFolderPlus"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faBoxes"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faArrowsV"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faFolder"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faArrowCircleLeft"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faAxe"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faCampground"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faLongArrowAltRight"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faHandHoldingUsd"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faScrubber"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faSearch"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faRadar"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faExchangeAlt"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faVolumeSlash"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faRulerCombined"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faMobileAndroidAlt"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faLightbulb"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faKeyboard"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faPenSquare"
    ),
    import(
      /* webpackChunkName: "faChunk27" */ "@fortawesome/pro-light-svg-icons/faLevelUp"
    ),
  ]);
const chunk28 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faSort"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faArrowToTop"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faCertificate"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faChartScatter"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faRectanglePortrait"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faPresentation"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faFolderTree"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faCashRegister"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faUserChart"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faCopy"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faLocationArrow"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faSuitcase"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faFileAlt"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faPen"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faPencil"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faChevronCircleRight"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faDungeon"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faBurrito"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faStepForward"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faCommentAltSlash"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faUsersCrown"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faBrackets"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faOilCan"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faHouse"
    ),
    import(
      /* webpackChunkName: "faChunk28" */ "@fortawesome/pro-light-svg-icons/faFutbol"
    ),
  ]);
const chunk29 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faArrowLeft"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faIcons"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faArrowsAltH"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faFileAudio"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faRulerVertical"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faExpandAlt"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faAlarmSnooze"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faHome"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faShip"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faLaptop"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faFileUser"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faOilTemp"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faExchange"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faBellExclamation"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faTextWidth"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faTablet"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faPallet"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faBus"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faChevronSquareDown"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faBellSchoolSlash"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faSteeringWheel"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faExpandArrowsAlt"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faPills"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faShoppingBasket"
    ),
    import(
      /* webpackChunkName: "faChunk29" */ "@fortawesome/pro-light-svg-icons/faCogs"
    ),
  ]);
const chunk30 = () =>
  Promise.all([
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faMemory"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faTirePressureWarning"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faEngineWarning"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faPencilRuler"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faPlug"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faHeadSide"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faUniversity"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faLandmark"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faCommentSlash"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faTachometerSlowest"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faFileSpreadsheet"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faSortSizeUp"
    ),
    import(
      /* webpackChunkName: "faChunk30" */ "@fortawesome/pro-light-svg-icons/faPlus"
    ),
  ]);

const ChunkImportFunctions = [
  chunk0,
  chunk1,
  chunk2,
  chunk3,
  chunk4,
  chunk5,
  chunk6,
  chunk7,
  chunk8,
  chunk9,
  chunk10,
  chunk11,
  chunk12,
  chunk13,
  chunk14,
  chunk15,
  chunk16,
  chunk17,
  chunk18,
  chunk19,
  chunk20,
  chunk21,
  chunk22,
  chunk23,
  chunk24,
  chunk25,
  chunk26,
  chunk27,
  chunk28,
  chunk29,
  chunk30,
];

const ChunkNames = [
  new Set([
    "rectangle-wide",
    "treasure-chest",
    "truck-pickup",
    "archway",
    "hand-point-right",
    "exclamation-square",
    "gopuram",
    "battery-bolt",
    "phone-office",
    "redo",
    "hospital-user",
    "hand-receiving",
    "hand-pointer",
    "arrow-right",
    "vihara",
    "long-arrow-up",
    "car-crash",
    "car-bus",
    "radiation-alt",
    "camera-movie",
    "warehouse",
    "arrow-alt-square-right",
    "clock",
    "file-invoice",
    "arrow-square-left",
  ]),
  new Set([
    "gas-pump",
    "microchip",
    "angle-double-right",
    "car",
    "comments-alt-dollar",
    "print-slash",
    "stethoscope",
    "sort-alpha-down",
    "garage-open",
    "phone-square-alt",
    "arrow-from-bottom",
    "angle-double-left",
    "power-off",
    "car-building",
    "tv-retro",
    "long-arrow-alt-down",
    "tachometer-fastest",
    "vhs",
    "bolt",
    "analytics",
    "camcorder",
    "arrow-alt-up",
    "cloud-upload-alt",
    "arrow-alt-square-down",
    "video-plus",
  ]),
  new Set([
    "stream",
    "frown",
    "helicopter",
    "sort-size-down-alt",
    "desktop-alt",
    "abacus",
    "comment-alt-plus",
    "air-freshener",
    "folder-upload",
    "conveyor-belt-alt",
    "arrow-up",
    "bags-shopping",
    "chevron-double-left",
    "comments-alt",
    "poop",
    "amp-guitar",
    "street-view",
    "chart-area",
    "sitemap",
    "tire-flat",
    "project-diagram",
    "alarm-clock",
    "handshake",
    "folders",
    "computer-speaker",
  ]),
  new Set([
    "webcam-slash",
    "closed-captioning",
    "chevron-square-right",
    "igloo",
    "thunderstorm",
    "caret-left",
    "thumbs-down",
    "caravan-alt",
    "outlet",
    "expand-arrows",
    "star-exclamation",
    "caret-square-down",
    "drone-alt",
    "external-link-square-alt",
    "location-slash",
    "sort-shapes-down",
    "comment-times",
    "user-hard-hat",
    "piggy-bank",
    "camera",
    "rectangle-landscape",
    "compress-arrows-alt",
    "comment-dollar",
    "sim-card",
    "dolly-empty",
  ]),
  new Set([
    "id-card",
    "id-card-alt",
    "film-alt",
    "bell",
    "home-lg",
    "sort-amount-down-alt",
    "drone",
    "sort-amount-up-alt",
    "volume-mute",
    "money-bill-alt",
    "hospitals",
    "album-collection",
    "map-marked-alt",
    "brush",
    "taxi",
    "thumbs-up",
    "toggle-off",
    "atom-alt",
    "jug",
    "table",
    "compress",
    "paint-roller",
    "poll",
    "arrow-alt-right",
    "chart-line",
  ]),
  new Set([
    "hand-point-left",
    "radiation",
    "arrows",
    "video-slash",
    "car-wash",
    "money-check-edit",
    "newspaper",
    "construction",
    "layer-plus",
    "place-of-worship",
    "arrow-to-left",
    "video",
    "house-flood",
    "tablet-rugged",
    "desktop",
    "user-tag",
    "synagogue",
    "receipt",
    "copyright",
    "arrow-alt-circle-down",
    "arrow-alt-circle-right",
    "truck-container",
    "sort-shapes-down-alt",
    "broadcast-tower",
    "heat",
  ]),
  new Set([
    "ruler-triangle",
    "umbrella",
    "pause",
    "user-tie",
    "gas-pump-slash",
    "battery-quarter",
    "funnel-dollar",
    "angle-down",
    "repeat-1",
    "mouse-pointer",
    "badge-dollar",
    "farm",
    "check-square",
    "angle-double-up",
    "sms",
    "forklift",
    "caret-right",
    "house-damage",
    "sort-size-down",
    "comment-medical",
    "money-check-alt",
    "folder-times",
    "user-secret",
    "scanner-touchscreen",
    "compress-alt",
  ]),
  new Set([
    "compass",
    "star-half",
    "check",
    "retweet",
    "long-arrow-down",
    "sort-alt",
    "arrow-alt-to-left",
    "smile-plus",
    "watch-calculator",
    "brain",
    "dolly-flatbed-empty",
    "code",
    "barcode-scan",
    "shipping-timed",
    "car-bump",
    "coins",
    "camera-retro",
    "caret-circle-right",
    "briefcase",
    "fire",
    "satellite",
    "chevron-square-up",
    "arrow-circle-down",
    "undo-alt",
    "arrow-alt-to-bottom",
  ]),
  new Set([
    "arrow-from-left",
    "window-restore",
    "album",
    "church",
    "window-alt",
    "arrow-alt-from-top",
    "warehouse-alt",
    "angle-left",
    "cloud-upload",
    "sort-up",
    "balance-scale",
    "tools",
    "angle-double-down",
    "microphone",
    "poll-h",
    "shovel",
    "thumbtack",
    "trophy-alt",
    "undo",
    "charging-station",
    "socks",
    "tachometer-alt-fast",
    "comment-music",
    "comment-smile",
    "sort-down",
  ]),
  new Set([
    "mosque",
    "volume-up",
    "lightbulb-exclamation",
    "columns",
    "chevron-square-left",
    "star",
    "file-chart-pie",
    "tachometer-alt-average",
    "link",
    "info-circle",
    "tachometer",
    "calendar",
    "arrow-square-right",
    "burn",
    "chart-bar",
    "badge-check",
    "repeat-alt",
    "chevron-circle-up",
    "comment-alt-dollar",
    "pen-nib",
    "sparkles",
    "comment-alt-edit",
    "bookmark",
    "truck",
    "ban",
  ]),
  new Set([
    "comment-lines",
    "sign-out-alt",
    "sensor-fire",
    "sort-alpha-down-alt",
    "database",
    "industry",
    "shield-check",
    "tags",
    "sort-shapes-up-alt",
    "magic",
    "wallet",
    "exclamation-circle",
    "hospital",
    "tachometer-alt-slowest",
    "volume",
    "exclamation",
    "money-check",
    "search-location",
    "camera-polaroid",
    "edit",
    "battery-slash",
    "monument",
    "angle-right",
    "house-night",
    "unlink",
  ]),
  new Set([
    "coffee",
    "comment-alt-medical",
    "water",
    "paste",
    "long-arrow-alt-left",
    "router",
    "solar-panel",
    "hammer",
    "share-square",
    "share-all",
    "backward",
    "print",
    "microphone-alt",
    "sort-numeric-down",
    "sensor",
    "chevron-circle-down",
    "arrow-square-up",
    "map-marked",
    "kaaba",
    "tv-music",
    "cart-arrow-down",
    "envelope-open-dollar",
    "car-side",
    "fast-backward",
    "stop",
  ]),
  new Set([
    "dolly-flatbed-alt",
    "mind-share",
    "envelope-open-text",
    "comment-alt-exclamation",
    "motorcycle",
    "repeat",
    "tachometer-alt-fastest",
    "dolly-flatbed",
    "browser",
    "tablet-android",
    "cabinet-filing",
    "tv-alt",
    "clinic-medical",
    "comment-minus",
    "house-day",
    "podium",
    "save",
    "barcode-alt",
    "share",
    "school",
    "sack-dollar",
    "shopping-bag",
    "arrow-to-right",
    "comment-alt-minus",
    "volume-down",
  ]),
  new Set([
    "atom",
    "satellite-dish",
    "hospital-alt",
    "chevron-right",
    "sd-card",
    "hand-point-down",
    "store-alt",
    "arrow-alt-to-top",
    "car-mechanic",
    "hard-hat",
    "level-down-alt",
    "dumpster",
    "garage",
    "poo-storm",
    "fax",
    "pencil-alt",
    "calendar-plus",
    "tachometer-alt",
    "phone-volume",
    "user-crown",
    "store",
    "sensor-smoke",
    "hand-holding-box",
    "box-check",
    "megaphone",
  ]),
  new Set([
    "caret-circle-down",
    "recycle",
    "tablet-android-alt",
    "eject",
    "caret-down",
    "shuttle-van",
    "sun",
    "trademark",
    "tasks",
    "bell-slash",
    "bells",
    "speaker",
    "balance-scale-left",
    "mobile-android",
    "train",
    "ruler",
    "address-book",
    "birthday-cake",
    "sync",
    "keynote",
    "car-alt",
    "phone-slash",
    "comment-exclamation",
    "sack",
    "hands-usd",
  ]),
  new Set([
    "car-battery",
    "podcast",
    "chart-network",
    "long-arrow-alt-up",
    "camera-alt",
    "envelope-open",
    "chevron-left",
    "torii-gate",
    "tasks-alt",
    "caret-circle-left",
    "microphone-slash",
    "mail-bulk",
    "external-link-square",
    "usb-drive",
    "chart-pie-alt",
    "shopping-cart",
    "comment-alt",
    "audio-description",
    "truck-monster",
    "bullseye-arrow",
    "webcam",
    "photo-video",
    "island-tropical",
    "tachometer-fast",
    "address-card",
  ]),
  new Set([
    "people-arrows",
    "sensor-alert",
    "arrow-alt-from-bottom",
    "calendar-exclamation",
    "chimney",
    "leaf",
    "comment-alt-dots",
    "angle-up",
    "comment-alt-check",
    "calendar-alt",
    "history",
    "award",
    "microscope",
    "caret-square-left",
    "box",
    "alarm-exclamation",
    "credit-card-blank",
    "speakers",
    "rss",
    "money-bill-wave",
    "external-link-alt",
    "inbox-out",
    "cloud-download",
    "level-down",
    "expand",
  ]),
  new Set([
    "comments-dollar",
    "lamp-desk",
    "upload",
    "random",
    "users",
    "envelope-square",
    "shredder",
    "lightbulb-dollar",
    "phone-laptop",
    "hotel",
    "fan",
    "landmark-alt",
    "dot-circle",
    "map-marker-exclamation",
    "percentage",
    "tire",
    "trophy",
    "home-lg-alt",
    "scanner-image",
    "home-alt",
    "sync-alt",
    "camera-home",
    "graduation-cap",
    "marker",
    "waveform-path",
  ]),
  new Set([
    "shield-alt",
    "tachometer-alt-slow",
    "folder-download",
    "wifi-slash",
    "sort-shapes-up",
    "mobile",
    "stamp",
    "arrow-alt-from-left",
    "phone-plus",
    "file-chart-line",
    "user",
    "skull-crossbones",
    "balance-scale-right",
    "mouse-alt",
    "folder-minus",
    "wifi",
    "computer-classic",
    "user-plus",
    "book",
    "pen-fancy",
    "pallet-alt",
    "projector",
    "money-bill",
    "comment",
    "arrow-alt-down",
  ]),
  new Set([
    "fast-forward",
    "volume-off",
    "comment-edit",
    "disc-drive",
    "chevron-double-up",
    "rss-square",
    "tally",
    "arrow-from-right",
    "seedling",
    "chart-line-down",
    "headphones",
    "bullhorn",
    "betamax",
    "coin",
    "forward",
    "film-canister",
    "arrows-alt",
    "long-arrow-left",
    "arrow-alt-circle-up",
    "server",
    "image-polaroid",
    "archive",
    "arrow-alt-square-left",
    "donate",
    "comment-alt-lines",
  ]),
  new Set([
    "rv",
    "business-time",
    "clipboard-list",
    "sign-in-alt",
    "tag",
    "check-circle",
    "stop-circle",
    "arrow-circle-up",
    "battery-three-quarters",
    "user-shield",
    "mp-3-player",
    "briefcase-medical",
    "triangle",
    "trailer",
    "sort-alpha-up",
    "chevron-double-right",
    "badge",
    "tire-rugged",
    "container-storage",
    "comment-alt-times",
    "sign-out",
    "flux-capacitor",
    "brackets-curly",
    "film",
    "download",
  ]),
  new Set([
    "digging",
    "wind",
    "user-lock",
    "cloud-download-alt",
    "boombox",
    "gift-card",
    "heart",
    "sticky-note",
    "city",
    "text-height",
    "arrow-alt-left",
    "folder-open",
    "sort-numeric-down-alt",
    "bell-on",
    "exclamation-triangle",
    "chevron-circle-left",
    "user-friends",
    "play-circle",
    "quote-left",
    "sort-amount-down",
    "gem",
    "battery-full",
    "pause-circle",
    "paperclip",
    "cars",
  ]),
  new Set([
    "expand-wide",
    "arrows-alt-v",
    "pen-alt",
    "reply-all",
    "industry-alt",
    "music",
    "cart-plus",
    "clipboard-check",
    "microphone-alt-slash",
    "arrow-alt-square-up",
    "calculator",
    "file",
    "ad",
    "compress-wide",
    "percent",
    "reply",
    "tv",
    "traffic-cone",
    "sort-amount-up",
    "quote-right",
    "comment-dots",
    "sensor-on",
    "scanner",
    "smile",
    "garage-car",
  ]),
  new Set([
    "key",
    "gift",
    "drafting-compass",
    "arrow-circle-right",
    "bullseye",
    "language",
    "bullseye-pointer",
    "file-invoice-dollar",
    "tachometer-average",
    "money-check-edit-alt",
    "toolbox",
    "sort-numeric-up",
    "redo-alt",
    "cut",
    "inbox-in",
    "tachometer-slow",
    "comment-alt-smile",
    "battery-empty",
    "caret-square-up",
    "arrow-alt-from-right",
    "conveyor-belt",
    "step-backward",
    "icons-alt",
    "eraser",
    "plane-departure",
  ]),
  new Set([
    "caret-up",
    "retweet-alt",
    "chevron-double-down",
    "building",
    "comment-plus",
    "mouse",
    "car-tilt",
    "ruler-horizontal",
    "signal-stream",
    "glasses",
    "credit-card",
    "sort-numeric-up-alt",
    "microphone-stand",
    "money-bill-wave-alt",
    "search-dollar",
    "hdd",
    "file-video",
    "inventory",
    "wind-warning",
    "ethernet",
    "arrows-h",
    "caret-square-right",
    "poo",
    "play",
    "comment-check",
  ]),
  new Set([
    "mobile-alt",
    "map-marker-alt",
    "wind-turbine",
    "suitcase-rolling",
    "phone-square",
    "registered",
    "car-garage",
    "highlighter",
    "arrow-square-down",
    "star-half-alt",
    "wrench",
    "chart-pie",
    "sort-alpha-up-alt",
    "laptop-house",
    "level-up-alt",
    "wagon-covered",
    "lightbulb-slash",
    "scanner-keyboard",
    "file-exclamation",
    "badge-percent",
    "hand-heart",
    "repeat-1-alt",
    "lightbulb-on",
    "cloud-music",
    "hand-point-up",
  ]),
  new Set([
    "sort-size-up-alt",
    "tablet-alt",
    "clipboard",
    "waveform",
    "laptop-code",
    "meh",
    "arrow-down",
    "battery-half",
    "location",
    "screwdriver",
    "compact-disc",
    "shipping-fast",
    "caravan",
    "credit-card-front",
    "globe-asia",
    "arrow-from-top",
    "head-side-headphones",
    "phone-alt",
    "coffee-pot",
    "book-open",
    "arrow-alt-to-right",
    "circle",
    "toggle-on",
    "arrow-alt-circle-left",
    "dolly",
  ]),
  new Set([
    "fire-alt",
    "ambulance",
    "bus-alt",
    "barcode-read",
    "caret-circle-up",
    "folder-plus",
    "boxes",
    "arrows-v",
    "folder",
    "arrow-circle-left",
    "axe",
    "campground",
    "long-arrow-alt-right",
    "hand-holding-usd",
    "scrubber",
    "search",
    "radar",
    "exchange-alt",
    "volume-slash",
    "ruler-combined",
    "mobile-android-alt",
    "lightbulb",
    "keyboard",
    "pen-square",
    "level-up",
  ]),
  new Set([
    "sort",
    "arrow-to-top",
    "certificate",
    "chart-scatter",
    "rectangle-portrait",
    "presentation",
    "folder-tree",
    "cash-register",
    "user-chart",
    "copy",
    "location-arrow",
    "suitcase",
    "file-alt",
    "pen",
    "pencil",
    "chevron-circle-right",
    "dungeon",
    "burrito",
    "step-forward",
    "comment-alt-slash",
    "users-crown",
    "brackets",
    "oil-can",
    "house",
    "futbol",
  ]),
  new Set([
    "arrow-left",
    "icons",
    "arrows-alt-h",
    "file-audio",
    "ruler-vertical",
    "expand-alt",
    "alarm-snooze",
    "home",
    "ship",
    "laptop",
    "file-user",
    "oil-temp",
    "exchange",
    "bell-exclamation",
    "text-width",
    "tablet",
    "pallet",
    "bus",
    "chevron-square-down",
    "bell-school-slash",
    "steering-wheel",
    "expand-arrows-alt",
    "pills",
    "shopping-basket",
    "cogs",
  ]),
  new Set([
    "memory",
    "tire-pressure-warning",
    "engine-warning",
    "pencil-ruler",
    "plug",
    "head-side",
    "university",
    "landmark",
    "comment-slash",
    "tachometer-slowest",
    "file-spreadsheet",
    "sort-size-up",
    "plus",
  ]),
];

export function importChunkByName(
  iconName: string
): (() => Promise<IconDefinition[]>) | null {
  const findChunkIdx = ChunkNames.findIndex((chunk) => chunk.has(iconName));
  if (findChunkIdx < 0) return null;
  const chunkImportFn: (() => Promise<any[]>) | undefined =
    ChunkImportFunctions[findChunkIdx];
  if (!chunkImportFn) return null;
  return () =>
    chunkImportFn().then((imports: any[]) =>
      imports.map((i) => i.definition as IconDefinition)
    );
}
